import partyimageone from '../assets/images/party-img-1_new.jpg'
import partyimagetwo from '../assets/images/view-futuristic-concert_new.jpg'
import aboutus1 from '../assets/images/beautiful-christmas-interior-decoration_new.jpg'
import aboutus2 from '../assets/images/event-hall-with-white-purple-balloons_new.jpg'
import support1 from '../assets/images/table-arrangement-birthday-event-with-cake-glass-champagne_new.jpg'
import venue1 from '../assets/images/venue1_new.jpg'
import catering1 from '../assets/images/catering1_new.jpg'
import decor1 from '../assets/images/decor1_new.jpg'
import Entertainment1 from '../assets/images/entertainment1_new.jpg'
import activities1 from '../assets/images/wedding activities1_new.jpg'
import support3 from '../assets/images/how-is-rich-people-have-supper-prepared-desk-waiting-food-visitors-evening-time_new.jpg'
import contactus1 from '../assets/images/Call center 1.gif'
import bannernew4 from '../assets/images/banner-new-4_new.jpg'
import newchat from '../assets/images/chat_new.jpg'
import cakelive from '../assets/images/cakelive_new.jpg'
import giftamico from '../assets/images/Gift-amico_new.png'
import banner from '../assets/images/antique-gold-flower-light-candle_new.jpg'
import banner2 from '../assets/images/just-married-dancing_new.jpg'
import landinginitial from '../assets/images/proposal-img-1.png'
import proposal from '../assets/images/proposal-img 2.png'
import design1 from '../assets/images/design-1.png'
import design2 from '../assets/images/design-2.png'
import design3 from '../assets/images/design-3.png'
import design4 from '../assets/images/design-4.png'
import bandhamlogo from '../assets/images/bandham-logo1.png'
import leaf1 from '../assets/images/leaf-1.png'
import leaf2 from '../assets/images/leaf-2.png'
import couple1 from '../assets/images/couple-1.jpg'
import couple2 from '../assets/images/couple-8.jpg'
import couple3 from '../assets/images/couple-3.jpg'
import couple4 from '../assets/images/couple-4.jpg'
import couple5 from '../assets/images/couple-5.jpg'
import grass1 from '../assets/images/grass-1.png'
import hinduwedding from '../assets/images/hindu-wedding.png'
import flower1 from '../assets/images/flower-new-1.png'
import flower2 from '../assets/images/bouquet-2.png'
import wedding1 from '../assets/images/wedding-2.jpg'
import wedding3 from '../assets/images/wedding-3.jpg'
import wedding4 from '../assets/images/wedding-4.jpg'
import frame1 from '../assets/images/frame-bag.png'
import recentcoup1 from '../assets/images/recent-coup-1.jpg'
import recentcoup2 from '../assets/images/recent-coup-2.jpg'
import recentcoup3 from '../assets/images/recent-coup-3.jpg'
import book from '../assets/images/book-close.jpeg'
import bandhamimg from '../assets/images/bandham-wedding-img.jpg'
import bandhamphone1 from '../assets/images/bandham-phone1.png'
import bandhamphone2 from '../assets/images/bandham-phone2.png'
import bandhamphone3 from '../assets/images/bandham-phone3.png'
import bandhamphone4 from '../assets/images/bandham-phone4.png'
import successgirl from '../assets/images/success-girl.png'
import matchmake from '../assets/images/matchmake2.png'
import sendinterest from '../assets/images/send-interest.png'
import profileinfo from '../assets/images/profile-info.png'
import gettingmarriage1 from '../assets/images/getting-marriage-1.jpeg'
import peacock1 from '../assets/images/peacock-1.png'
import weddingnew1 from '../assets/images/wedding-new-1.png'
import startmeetups from '../assets/images/start-meetups.png'
import bandhamscreen1 from '../assets/images/bandham-screen1.png'
import bandhamscreen2 from '../assets/images/bandham-screen2.png'
import bandhamscreen3 from '../assets/images/bandham-screen3.png'
import bandhamscreen4 from '../assets/images/bandham-screen4.png'
import bandhamscreen5 from '../assets/images/bandham-screen5.png'
import bandhamscreen6 from '../assets/images/bandham-screen6.png'


// import venue1 from '../assets/images/venue1.jpg'


export default {
    partyimageone, partyimagetwo, aboutus1, aboutus2, support1, venue1, catering1, decor1, Entertainment1, activities1, support3, contactus1, bannernew4, newchat, cakelive, giftamico, banner, banner2, landinginitial, proposal, design1, design2, design3, design4, bandhamlogo, leaf1, leaf2, couple1, couple2,
    couple3, couple4, couple5, grass1, hinduwedding, flower1, flower2, wedding1, wedding3, wedding4, frame1, recentcoup1, recentcoup2, recentcoup3, book, bandhamimg, bandhamphone1, bandhamphone2, bandhamphone3, bandhamphone4, successgirl, matchmake, sendinterest, profileinfo, gettingmarriage1, peacock1,
    weddingnew1, startmeetups, bandhamscreen1, bandhamscreen2, bandhamscreen3, bandhamscreen4, bandhamscreen5, bandhamscreen6
}