import React, { useState, useEffect } from "react";
import images from "../../constants/images";
import "../Header/Header.css";
import { FaPaperPlane } from "react-icons/fa";
import Navbar from "../Navbar/Navbar";
import { RiAppleLine } from "react-icons/ri";
import { FaGooglePlay } from "react-icons/fa";
import WhatWeDo from "../WhatWeDo/WhatWeDo";
import OurServices from "../services/OurServices";
import Footer from "../Footer/Footer";
import Support from "../Support/Support";
import TrustedCouples from "../trustedcouples/TrustedCouples";
import AboutUs from "../aboutus/AboutUs";
import HowItWorks from "../howitworks/HowItWorks";
import WorkFlow from "../workflow/WorkFlow";
import BandhamScreens from "../bandhamscreens/BandhamScreens";

const Header = () => {
    // State to keep track of current image index
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    // State for managing the loading screen
    const [loading, setLoading] = useState(true);

    // Array of images
    const bannerImages = [images.couple4, images.couple2, images.couple5];

    useEffect(() => {
        // Function to change image index every 3 seconds
        const intervalId = setInterval(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % bannerImages.length);
        }, 3000);

        // Clear interval on component unmount
        return () => clearInterval(intervalId);
    }, []); // Empty dependency array ensures effect runs only once on component mount

    useEffect(() => {
        // Set loading to false after 2 seconds
        const timer = setTimeout(() => {
            setLoading(false);
        }, 2000);

        // Clear timer on component unmount
        return () => clearTimeout(timer);
    }, []);

    if (loading) {
        return (
            <div className="main-one">
                <div className="top-design">
                    <img className="design-one" src={images.design1} alt="Loading" />
                    <img className="design-one" src={images.design2} alt="Loading" />
                </div>
                <div className="loading-screen">
                    <img src={images.landinginitial} alt="Loading" className="loading-image" data-aos="fade-right" data-aos-easing="linear"
                        data-aos-duration="1500" />
                    <img src={images.proposal} alt="Loading" className="loading-image" data-aos="fade-left" data-aos-easing="linear"
                        data-aos-duration="1500" />
                </div>
                <div className="bottom-design">
                    <img className="design-two" src={images.design3} alt="Loading" />
                    <img className="design-two" src={images.design4} alt="Loading" />
                </div>
            </div>
        );
    }

    return (
        <div className="fade-in">
            <header className="header flex" id="header">
                <div className="leaf-main">
                    <img className="leaf-one" src={images.leaf1}></img>
                    <img className="leaf-two" src={images.leaf2}></img>
                </div>
                <Navbar />
                <div className="download-btns">
                    <h1><span className="number-one">#1</span>MATRIMONY</h1>
                    <h1 style={{fontSize: '80px'}}>Find Your</h1>
                    <h1 className="right-match">Right Match <span className="here-text">Here</span></h1>
                </div>
                <div className="main-banner">
                    <img src={bannerImages[currentImageIndex]} alt={`Banner ${currentImageIndex + 1}`} />
                </div>
                <OurServices />
                <TrustedCouples />
                <AboutUs /> 
                <WorkFlow />
                <BandhamScreens />
                <HowItWorks />
                <Support />
                <Footer />
            </header>
        </div>
    );
};

export default Header;
