import React from 'react';
import { useNavigate } from 'react-router-dom';
import "../Footer/Footer.css";
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';

const Footer = () => {
    const navigate = useNavigate();

    const handleNavigate = (path) => {
        navigate(path);
    };

    return (
        <footer className='footer bg-dark text-white py-6' id='footer'>
            <div className='container'>
                <div className='footer-content text-center'>
                        <div className='foot-last-text' style={{ display: 'flex', justifyContent: 'space-between', fontSize: '15px'}}>
                            <p>Terms and Conditions</p>
                            <p>Privacy Policy</p>
                            <p>Return And Replacement</p>
                        </div>
                    <div className='footer-social-icons flex flex-center'>
                        <a href='#' className='bg-red flex flex-center'><FaFacebookF /></a>
                        <a href='#' className='bg-red flex flex-center'><FaTwitter /></a>
                        <a href='#' className='bg-red flex flex-center'><FaInstagram /></a>
                        <a href='#' className='bg-red flex flex-center'><FaLinkedin /></a>
                    </div>
                    <div className='footer-bottom-text'>
                        <p className='text fs-16'>All rights reserved.</p>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
