import React from 'react';
import images from '../../constants/images';
import data from '../../constants/data';
import '../trustedcouples/TrustedCouples.css';
import '../workflow/WorkFlow.css'
import { RiLoginBoxFill } from "react-icons/ri";


const WorkFlow = () => {
    return (
        <div>
            <div style={{background: '#fffbf1'}} className='flowers-main'>
                <img style={{height: '50px', width: '50px'}} className="flower" src={images.flower1} alt="Flower 1" />
                <h2 style={{ color: '#C48C46' }} className='heads-text'>How it works</h2>
                <img style={{height: '50px', width: '50px'}} className="flower" src={images.flower2} alt="Flower 2" />
            </div>
            <div className='main-timeline'>
                <div className='workflow-timeline'>
                    <div className='workflow-container left-workflow-container' data-aos="fade-up"
                    data-aos-duration="3000">
                        <h5><img style={{ height: '200px', width: '200px' }} src={images.successgirl} /></h5>
                        <div className='workflow-text-box'>
                            <h2>REGISTER</h2>
                            <small>based on similarities</small>
                            <p>The success nfdfngknjarbjvudfvvuiviuvefivbvbdvd dvdbvugfvugfvgfdv dfuivhdfuigviufvhifuhvudfvudfhvdfiubvfdiuhv dkjvdfjbv</p>
                        </div>
                    </div>
                    <div className='workflow-container right-workflow-container' data-aos="fade-up"
                    data-aos-duration="3000">
                        <h5><img style={{ height: '200px', width: '200px' }} src={images.matchmake} /></h5>
                        <div className='workflow-text-box'>
                            <h2>FIND YOUR MATCH</h2>
                            <small>based on similarities</small>
                            <p>The success nfdfngknjarbjvudfvvuiviuvefivbvbdvd dvdbvugfvugfvgfdv dfuivhdfuigviufvhifuhvudfvudfhvdfiubvfdiuhv dkjvdfjbv</p>
                        </div>
                    </div>
                    <div className='workflow-container left-workflow-container' data-aos="fade-up"
                    data-aos-duration="3000">
                        <h5><img style={{ height: '200px', width: '200px' }} src={images.sendinterest} /></h5>
                        <div className='workflow-text-box'>
                            <h2>SEND INTEREST</h2>
                            <small>based on location</small>
                            <p>The success nfdfngknjarbjvudfvvuiviuvefivbvbdvd dvdbvugfvugfvgfdv dfuivhdfuigviufvhifuhvudfvudfhvdfiubvfdiuhv dkjvdfjbv</p>
                        </div>
                    </div>
                    <div className='workflow-container right-workflow-container' data-aos="fade-up"
                    data-aos-duration="3000">
                        <h5><img style={{ height: '200px', width: '200px' }} src={images.profileinfo}></img></h5>
                        <div className='workflow-text-box'>
                            <h2>GET PROFILE INFORMATION</h2>
                            <small>based on location</small>
                            <p>The success nfdfngknjarbjvudfvvuiviuvefivbvbdvd dvdbvugfvugfvgfdv dfuivhdfuigviufvhifuhvudfvudfhvdfiubvfdiuhv dkjvdfjbv</p>
                        </div>
                    </div>
                    <div className='workflow-container left-workflow-container' data-aos="fade-up"
                    data-aos-duration="3000">
                        <h5><img style={{ height: '200px', width: '200px' }} src={images.startmeetups} /></h5>
                        <div className='workflow-text-box'>
                            <h2>START MEETUPS</h2>
                            <small>based on location</small>
                            <p>The success nfdfngknjarbjvudfvvuiviuvefivbvbdvd dvdbvugfvugfvgfdv dfuivhdfuigviufvhifuhvudfvudfhvdfiubvfdiuhv dkjvdfjbv</p>
                        </div>
                    </div>
                    <div className='workflow-container right-workflow-container' data-aos="fade-up"
                    data-aos-duration="3000">
                        <h5><img style={{ height: '200px', width: '200px', }} src={images.weddingnew1}></img></h5>
                        <div className='workflow-text-box'>
                            <h2>GETTING MARRIAGE</h2>
                            <small>based on location</small>
                            <p>The success nfdfngknjarbjvudfvvuiviuvefivbvbdvd dvdbvugfvugfvgfdv dfuivhdfuigviufvhifuhvudfvudfhvdfiubvfdiuhv dkjvdfjbv</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WorkFlow;
