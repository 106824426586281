import React from 'react';
import images from '../../constants/images';
import '../trustedcouples/TrustedCouples.css';
import '../bandhamscreens/BandhamScreens.css';

const BandhamScreens = () => {
    return (
        <div>
            <div style={{ background: '#fffbf1' }} className='flowers-main'>
                <img style={{ height: '50px', width: '50px' }} className="flower" src={images.flower1} alt="Flower 1" />
                <h2 style={{ color: '#C48C46' }} className='heads-text'>Mobile App Screens</h2>
                <img style={{ height: '50px', width: '50px' }} className="flower" src={images.flower2} alt="Flower 2" />
            </div>
            <div className='screen-slider'>
                <input type='radio' name='card' id='c-1'></input>
                <input type='radio' name='card' id='c-2'></input>
                <input type='radio' name='card' id='c-3' defaultChecked></input>
                <input type='radio' name='card' id='c-4'></input>
                <input type='radio' name='card' id='c-5'></input>
                <div className='screen-cards'>
                    {Array.from({ length: 5 }, (_, index) => (
                        <label key={index} htmlFor={`c-${index + 1}`} className='screen-item'>
                            <div className='screen_main_content'>
                                <div className='screen-img'>
                                    <img src={images[`bandhamscreen${index + 1}`]} alt={`Bandham Screen ${index + 1}`} />
                                </div>
                            </div>
                        </label>
                    ))}
                </div>
                <div className='screen-dots'>
                    {Array.from({ length: 5 }, (_, index) => (
                        <label key={index} htmlFor={`c-${index + 1}`}></label>
                    ))}
                </div>
            </div>

        </div>
    );
}

export default BandhamScreens;
