import React from 'react'
import './OurServices.css'
import images from '../../constants/images';
import data from '../../constants/data';

const OurServices = () => {
    return (
        <section className='our-services' id='our-services'>
            <div className="main-service">
                <div className='flowers-main'>
                    <img className="flower" src={images.flower1} alt="Flower 1" />
                    <h2 style={{ color: '#C48C46' }} className='heads-text'>Our Services</h2>
                    <img style={{ marginLeft: '6px' }} className="flower" src={images.flower2} alt="Flower 2" />
                </div>
                <div className='services-cards-container'>
                    <div className='services-cards-wrapper'>
                        <div className='services-cards-area'>
                            <div className='services-cards-card'>
                                <img src={images.wedding1}></img>
                                <div className='services-cards-overlay'>
                                    <h3>BROWSE PROFILES</h3>
                                    <p>1200+ profiles</p>
                                    <a href='#'>Book Now</a>
                                </div>
                            </div>
                            <div className='services-cards-card'>
                                <img src={images.wedding4}></img>
                                <div className='services-cards-overlay'>
                                    <h3>PHOTO GALLERY</h3>
                                    <p>400+ gallery</p>
                                    <a href='#'>Book Now</a>
                                </div>
                            </div>
                            <div className='services-cards-card'>
                                <img src={images.wedding3}></img>
                                <div className='services-cards-overlay'>
                                    <h3>WEDDING</h3>
                                    <p>1000+ successful weddings</p>
                                    <a href='#'>Book Now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OurServices
