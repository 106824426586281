import React from 'react';
import images from '../../constants/images';
import data from '../../constants/data';
import '../trustedcouples/TrustedCouples.css';
import '../howitworks/HowItWorks.css'

const HowItWorks = () => {
    return (
        <div className='main-timeline'>
            <div className='howitworks-timeline'>
                <div className='howitworks-container left-container'>
                    <img src={images.bandhamphone1}></img>
                    <div className='howitworks-text-box'>
                        <h2>SIMILAR INTERESTS</h2>
                        <small>based on similarities</small>
                        <p>The success nfdfngknjarbjvudfvvuiviuvefivbvbdvd dvdbvugfvugfvgfdv dfuivhdfuigviufvhifuhvudfvudfhvdfiubvfdiuhv dkjvdfjbv</p>
                    </div>
                </div>
                <div className='howitworks-container right-container'>
                    <img src={images.bandhamphone2}></img>
                    <div className='howitworks-text-box'>
                        <h2>SIMILAR INTERESTS</h2>
                        <small>based on similarities</small>
                        <p>The success nfdfngknjarbjvudfvvuiviuvefivbvbdvd dvdbvugfvugfvgfdv dfuivhdfuigviufvhifuhvudfvudfhvdfiubvfdiuhv dkjvdfjbv</p>
                    </div>
                </div>
                <div className='howitworks-container left-container'>
                    <img src={images.bandhamphone3}></img>
                    <div className='howitworks-text-box'>
                        <h2>LOCATION BASED</h2>
                        <small>based on location</small>
                        <p>The success nfdfngknjarbjvudfvvuiviuvefivbvbdvd dvdbvugfvugfvgfdv dfuivhdfuigviufvhifuhvudfvudfhvdfiubvfdiuhv dkjvdfjbv</p>
                    </div>
                </div>
                <div className='howitworks-container right-container'>
                    <img src={images.bandhamphone4}></img>
                    <div className='howitworks-text-box'>
                        <h2>LOCATION BASED</h2>
                        <small>based on location</small>
                        <p>The success nfdfngknjarbjvudfvvuiviuvefivbvbdvd dvdbvugfvugfvgfdv dfuivhdfuigviufvhifuhvudfvudfhvdfiubvfdiuhv dkjvdfjbv</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HowItWorks;
