import React from 'react';
import images from '../../constants/images';
import data from '../../constants/data';
import '../trustedcouples/TrustedCouples.css';
import '../aboutus/AboutUs.css'

const AboutUs = () => {
    return (
        <div>
            <img style={{ height: '20px' }} className='grass-image' src={images.grass1}></img>
            <section className='our-services' id='what-we-do'>
                <div style={{ backgroundColor: '#FFFBF1' }} className="main-service">
                    <div className='flowers-main'>
                        <img className="flower" src={images.flower1} alt="Flower 1" />
                        <h2 style={{ color: '#C48C46' }} className='heads-text'>About Us</h2>
                        <img style={{ marginLeft: '6px' }} className="flower" src={images.flower2} alt="Flower 2" />
                    </div>
                    <div className='aboutus-main'>
                        <div className='aboutus-image'>
                            <img src={images.bandhamimg} alt="About Us" />
                        </div>
                        <div className='aboutus-content'>
                            <h1 className='about-bandham-head'>WELCOME TO</h1>
                            <h1 className='about-bandham-sec-head'>BANDHAM</h1>
                            <p className='about-bandham-para'>Best wedding matrimony It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.

                                <span style={{ color: '#E5026B' }}>Click here</span> to Start you matrimony service now.</p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default AboutUs;
