import React from 'react';
import images from '../../constants/images';
import data from '../../constants/data';
import "../WhatWeDo/WhatWeDo.css";

const WhatWeDo = () => {
    return (
        <div>
            <img style={{height: '20px'}} className='grass-image' src={images.grass1}></img>
            <section className='what-we-do py-6' id='what-we-do'>
                <div className='main-about'>
                    <div className='container'>
                        <div className='section-title bg-dark'>
                            <h2 className='text-upper text-white text-center'>ABOUT US</h2>
                        </div>
                        <div className='what-we-do-content'>
                            {
                                data.what_we_do.map((whatItem, index) => {
                                    return (


                                        <div className='what-we-do-item grid text-center' key={index}>
                                            <div className='what-we-do-item-left' data-aos="fade-right">
                                                <h6 className='something-border-left'></h6>
                                                <img src={whatItem.image} alt='' className='mx-auto'></img>
                                            </div>
                                            <div className='what-we-do-item-right' data-aos="fade-left">
                                                <h5 className='text-upper fs-10'>{whatItem.title}</h5>
                                                <p className='text mx-auto'>{whatItem.paragraph}</p>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default WhatWeDo
