import React from 'react';
import images from '../../constants/images';
import data from '../../constants/data';
import '../trustedcouples/TrustedCouples.css';

const TrustedCouples = () => {
    return (
        <section className='our-services' id='our-services'>
            <div className="main-service">
                <div className='flowers-main'>
                    <img className="flower" src={images.flower1} alt="Flower 1" />
                    <h2 style={{ color: '#C48C46' }} className='heads-text'>Recent Couples</h2>
                    <img style={{ marginLeft: '6px' }} className="flower" src={images.flower2} alt="Flower 2" />
                </div>
                <div className='trusted-book'>
                    <input type='checkbox' id='c1' />
                    <input type='checkbox' id='c2' />
                    <input type='checkbox' id='c3' />
                    <div id='trusted-cover'><img src={images.recentcoup1} alt="Couple 1" /></div>
                    <div className='trusted-flip-book'>
                        <div className='trusted-flip' id='p1'>
                            <div className='trusted-back'>
                                <img src={images.recentcoup2} alt="Couple 2" />
                                <label className='trusted-back-btn' htmlFor="c1">Back</label>
                            </div>
                            <div className='trusted-front'>
                                <h2>Vivek Swetha</h2>
                                <p>dbjdbfrgfybjkdfgfhdbvkjdfbvhjdfbvhjdbvhjdfvbhdfbvdfjbvgfbvfdjvbfvbfdhbvfdhvbdfhbvdfhvbfdhvbd</p>
                                <label className='trusted-next-btn' htmlFor="c1">Next</label>
                            </div>
                        </div>
                        <div className='trusted-flip' id='p2'>
                            <div className='trusted-back'>
                                <img src={images.recentcoup3} alt="Couple 3" />
                                <label className='trusted-back-btn' htmlFor="c2">Back</label>
                            </div>
                            <div className='trusted-front'>
                                <h2>Chaithany Anjali</h2>
                                <p>dbjdbfrgfybjkdfgfhdbvkjdfbvhjdfbvhjdbvhjdfvbhdfbvdfjbvgfbvfdjvbfvbfdhbvfdhvbdfhbvdfhvbfdhvbd</p>
                                <label className='trusted-next-btn' htmlFor="c2">Next</label>
                            </div>
                        </div>
                        <div className='trusted-flip' id='p3'>
                            <div className='trusted-back'>
                                <img src={images.book} alt="Couple 2" />
                                <label className='trusted-back-btn' htmlFor="c3">Back</label>
                            </div>
                            <div className='trusted-front'>
                                <h2>Rahul Preethi</h2>
                                <p>dbjdbfrgfybjkdfgfhdbvkjdfbvhjdfbvhjdbvhjdfvbhdfbvdfjbvgfbvfdjvbfvbfdhbvfdhvbdfhbvdfhvbfdhvbd</p>
                                <label className='trusted-next-btn' htmlFor="c3">Next</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default TrustedCouples;
