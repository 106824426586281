import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './assets/js/script';
import Header from './components/Header/Header';
import WhatWeDo from './components/WhatWeDo/WhatWeDo';
import Features from './components/Features/Features';
import Packages from './components/Packages/Packages';
import Projects from './components/Projects/Projects';
import Team from './components/Team/Team';
import Support from './components/Support/Support';
import Footer from './components/Footer/Footer';
import AOS from 'aos';
import 'aos/dist/aos.css';
import OurServices from './components/services/OurServices';
import TermsAndConditions from './components/termsandconditions/TermsAndConditions';
import PrivacyPolicy from './components/privacypolicy/PrivacyPolicy';
import ReturnAndReplacement from './components/returnandreplacement/ReturnAndReplacement';
import TrustedCouples from './components/trustedcouples/TrustedCouples';
import AboutUs from './components/aboutus/AboutUs';
import WorkFlow from './components/workflow/WorkFlow';

AOS.init();

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Header />} />
        <Route path='/trustedcouples' element={<TrustedCouples />} />
        <Route path='/workflow' element={<WorkFlow />} />
        <Route path='/aboutus' element={<AboutUs />} />
        <Route path="/whatwedo" element={<WhatWeDo />} />
        <Route path="/features" element={<Features />} />
        <Route path="/packages" element={<Packages />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/team" element={<Team />} />
        <Route path="/support" element={<Support />} />
        <Route path="/our-services" element={<OurServices />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/returnandreplacement" element={<ReturnAndReplacement />} />
      </Routes>
    </div>
  );
}

export default App;
