import React, { useState } from 'react';
import "../Support/Support.css";
import images from '../../constants/images';
import { FaFacebook } from 'react-icons/fa';
import { BsLinkedin, BsTwitter, BsYoutube } from 'react-icons/bs';
import { LiaLinkedin } from 'react-icons/lia';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

const Support = () => {
    const [showModal, setShowModal] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        mobile: '',
        message: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission here
        console.log(formData);
        // Optionally, you can close the modal after submission
        setShowModal(false);
    };

    return (
        <section className='support' id='support'>
            <div className='support-main'>
                <div style={{ background: '#fffbf1' }} className='flowers-main'>
                    <img style={{ height: '50px', width: '50px' }} className="flower" src={images.flower1} alt="Flower 1" />
                    <h2 style={{ color: '#C48C46' }} className='heads-text'>Contact Us</h2>
                    <img style={{ height: '50px', width: '50px' }} className="flower" src={images.flower2} alt="Flower 2" />
                </div>
                <div className='sub-container'>
                    <div className='item'>
                        <div className='contact'>
                            <img src={images.couple4} alt="Couple"></img>
                        </div>
                        <div className='submit-form'>
                            <h4 className='third-text'>Contact Us</h4>
                            <form onSubmit={handleSubmit}>
                                <div className='input-box'>
                                    <p>Name</p>
                                    <TextField
                                        variant="outlined"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleInputChange}
                                        required
                                        fullWidth
                                        className='text-new-box'
                                    />
                                </div>
                                <div className='input-box'>
                                    <p>Email</p>
                                    <TextField
                                        variant="outlined"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleInputChange}
                                        required
                                        fullWidth
                                    />
                                </div>
                                <div className='input-box'>
                                    <p>Phone</p>
                                    <TextField
                                        variant="outlined"
                                        name="mobile"
                                        value={formData.mobile}
                                        onChange={handleInputChange}
                                        required
                                        fullWidth
                                    />
                                </div>
                                <div className='input-box'>
                                    <p>Message</p>
                                    <TextField
                                        variant="outlined"
                                        name="message"
                                        value={formData.message}
                                        onChange={handleInputChange}
                                        required
                                        multiline
                                        rows={4}
                                        fullWidth
                                    />
                                </div>
                                <Button
                                    style={{ fontSize: '15px', backgroundColor: '#EF8DBB' }}
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className='btn'
                                    fullWidth
                                >
                                    Submit
                                </Button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Support;
